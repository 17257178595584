<template>
  <Acl :permissions="['users-index']">
    <b-row no-gutters class="align-items-center justify-content-between mb-3">
      <!-- <b-col cols="7" class="mb-3 mb-lg-0 pb-2 pt-2 pt-lg-0 pb-lg-0">
        <a href="javascript:;"
          @click.prevent="() => showingPublished = null"
          :class="showingPublished === null ? 'text-dark font-weight-bold' : 'text-primary'">
          All
        </a>
        <a-divider type="vertical" />
        <a href="javascript:;"
          @click.prevent="() => showingPublished = 1"
          :class="showingPublished === 1 ? 'text-dark font-weight-bold' : 'text-primary'">
          Published
        </a>
        <a-divider type="vertical" />
        <a href="javascript:;"
          @click.prevent="() => showingPublished = 0"
          :class="showingPublished === 0 ? 'text-dark font-weight-bold' : 'text-primary'">
          Draft
        </a>
      </b-col> -->
      <b-col cols="5" lg="2" class="text-right pr-lg-2 mb-3 mb-lg-0">
        <b-button v-show="selectedUsers.length" @click.prevent="showDeleteConfirm(selectedUsers)" size="sm" variant="outline-danger"><i class="fe fe-trash"></i> Delete Selected</b-button>
      </b-col>
      <b-col lg="3" class="text-right">
        <a-form :form="form" @submit.prevent="search" layout="inline">
          <a-input-search v-model="q" placeholder="Search Users"></a-input-search>
        </a-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="px-3 card">
          <a-table
            :row-selection="{ selectedRowKeys: selectedUsers, onChange: onSelectChange }"
            :loading="loading"
            :columns="columns"
            :dataSource="users"
            :class="$style.table"
            :pagination="pagination"
            :scroll="{ x: 900 }"
            @change="tableChanged">
            <template slot="fullName" slot-scope="full_name, item">
              <h2 class="mb-0 h6">
                <router-link :title="`Edit: ${full_name}`" class="text-gray-6" :to="{ path: `users/edit/${item.id}`}">
                  {{ full_name }}
                </router-link>
              </h2>
              <small :class="item.active ? 'text-success' : 'text-warning'">{{ item.active ? 'Active' : 'Inactive' }}</small>
            </template>
            <template slot="roles" slot-scope="roles, item">
              {{ item.roles.map(m => m.name).join(', ')}}
            </template>
            <template slot="action" slot-scope="action, item">
              <div>
                <router-link title="Edit" :to="{ path: `users/edit/${item.id}`}" class="btn btn-sm btn-primary mr-2">
                  <i class="fe fe-edit" />
                </router-link>
                <button title="Delete" @click="showDeleteConfirm([item.id])" type="button" class="btn btn-sm btn-light">
                  <i class="fe fe-trash" />
                </button>
              </div>
            </template>
            <template slot="portals" slot-scope="portals">
              {{ portals.map((portal) => {
                return portal.title
              }).join(', ') }}
            </template>
          </a-table>
          <div :class="$style.tableFooter" class="text-center text-lg-left pb-4 pb-lg-0">
            <span class="text-muted">Showing <b>{{ users.length }}</b> of <b>{{ new Intl.NumberFormat().format(pagination.total) }}</b> Users</span>
          </div>
        </div>
      </b-col>
    </b-row>
  </Acl>
</template>
<script>
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
import Acl from '@/components/cleanui/system/ACL'
import moment from 'moment'

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      pagination: {},
      q: '',
      selectedUsers: [],
      showingPublished: null,
      sort: {
        column: '',
        value: '',
      },
      filters: {
        tags: [],
        createdBy: [],
        published_at: [],
      },
    }
  },
  created() {
    const vm = this
    vm.$store.dispatch('role/FETCH')
    vm.fetch()
  },
  computed: {
    ...mapState(['settings']),
    ...mapState('role', {
      roles: state => state.data,
    }),
    ...mapState('user', {
      users: state => state.data,
    }),
    loading() {
      return this.$store.state.user.loading
    },
    columns() {
      const vm = this
      const columns = [
        {
          title: 'Full Name',
          dataIndex: 'full_name',
          width: '30%',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'fullName' },
        },
        {
          title: 'Roles',
          dataIndex: 'roles',
          width: '10%',
          className: 'text-gray-6',
          scopedSlots: {
            customRender: 'roles',
            filterDropdown: 'filterDropdown',
          },
          filters: vm.roles.map((role) => {
            return {
              text: role.name,
              value: role.slug,
            }
          }),
        },
        {
          title: 'Username',
          dataIndex: 'username',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'username' },
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'phone' },
        },
        {
          title: 'Portals',
          dataIndex: 'portals',
          className: 'text-gray-6',
          width: '10%',
          scopedSlots: { customRender: 'portals' },
        },
        {
          dataIndex: 'action',
          className: 'text-right',
          scopedSlots: { customRender: 'action' },
          fixed: 'right',
        },
      ]
      return columns
    },
  },
  methods: {
    onDateChange(date, dateString, setSelectedKeys) {
      this.filters.published_at = [moment(dateString[0], 'DD-MM-YYYY'), moment(dateString[1], 'DD-MM-YYYY')]
      setSelectedKeys(dateString)
    },
    onSelectChange(selectedUsers) {
      this.selectedUsers = selectedUsers
    },
    // Filter & Search
    search() {
      const vm = this
      vm.fetch()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      // console.log(selectedKeys, dataIndex)
      confirm()
      // this.filters.published_at = [moment(), moment()]
    },
    handleReset(clearFilters) {
      clearFilters()
      this.filters.published_at = []
    },
    fetch(payload = {}) {
      const vm = this
      vm.$store.dispatch('user/FETCH', {
        portal_id: vm.settings.activePortal,
        q: vm.q,
        ...payload,
      }).then((res) => {
        // console.log(res)
        const pagination = {
          ...vm.pagination,
          current: Number(res.data.page),
          total: Number(res.data.total),
        }
        vm.pagination = pagination
      })
    },
    showDeleteConfirm(ids) {
      const vm = this

      Modal.confirm({
        title: 'Are you sure delete this user?',
        content: 'This user will deleted permanently',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          ids.forEach((id) => {
            vm.$store.dispatch('user/DELETE', id).then((res) => {
              vm.$notification.success({
                message: 'User Deleted',
                description: 'Successfully deleted.',
              })
              const index = vm.selectedUsers.indexOf(id)
              if (index > -1) {
                vm.selectedUsers.splice(index, 1)
              }
              vm.fetch()
            })
          })
        },
        onCancel() {},
      })
    },
    tableChanged(pagination, filters, sorter) {
      const vm = this
      vm.fetch({
        page: pagination.current,
        perPage: pagination.pageSize,
        role_slug_in: filters.roles,
      })
    },
  },
  watch: {
    'settings.activePortal': function() {
      const vm = this
      vm.fetch()
    },
    showingPublished(showingPublished) {
      const vm = this
      vm.fetch({
        is_published: showingPublished,
      })
    },
  },
  destroyed() {
    this.$store.commit('user/CLEAR_DATA')
  },
  components: {
    Acl,
  },
}
</script>
<style lang="scss" module>
// Import mixins
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/mixins";

.table {
  td {
    vertical-align: top;

    h5 {
      font-size: 16px;
      margin-bottom: 2px;
    }
  }
  &Footer {
    @include media-breakpoint-up(lg) {
      margin-top: -45px;
      margin-bottom: 45px;
    }
  }
}
</style>
